<template>
    <MatingDetailModule :select_list="select_list" :module_list="module_list" @handleClick="handleClick"
                        :searchInput='searchInput' @handleList="handleList"/>
</template>

<script>
    import MatingDetailModule from './../../../components/schoolAdministrator/course/MaterialsList'

    export default {
        name: "MatingDetail",
        props: ['courseInfos', 'nodeIndex', 'chapterIndex', 'select_list', 'module_list', 'searchInput'],
        components: {
            MatingDetailModule
        },
        methods: {
            handleClick(val) {
                this.$emit('handleSave', true)
            },
            handleList(val) {
                this.$emit('handleList', val)
            }
        }
    }
</script>

<style scoped>

</style>
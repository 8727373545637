<template>
    <div class="course-ware-content">
        <div class="resource-classify">
            <div class="head-title">个人资源</div>
            <div class="back-btn">
                <el-button type="primary" size="medium" @click="goBack">返 回</el-button>
            </div>
        </div>
        <el-scrollbar class="course-ware-list">
            <div class="course-ware">
                <table class="dataTabble" rules=rows>
                    <thead class="table-head">
                    <tr class="table-head-title">
                        <th class="th-title1">标题</th>
                        <th class="th-title2">文件类型</th>
                        <th class="th-title3">上传时间</th>
                        <th class="th-title4">操作</th>
                    </tr>
                    </thead>
                    <tbody class="table-body">
                        <tr v-for="(item,index) in resourceData" :key="index" class="table-trBox">
                        <td class="td-title">{{item.title}}</td>
                        <td>{{item.resource_file_type}}</td>
                        <td>{{item.create_time}}</td>
                        <td class="opt-box">
                            <div class="opt-innerBox">
                                <el-button type="text" @click="downloadFile(item)">下载</el-button>
                                <el-button type="text" @click="viewDetail(item)">查看详情</el-button>
                                <el-button type="text" style="color: #F50000" @click="delAppBtn(item)">删除
                                </el-button>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div v-if="resourceData.length === 0" class="no-data">
                    <span>暂无数据</span>
                </div>
            </div>
        </el-scrollbar>
        <div class="page-box">
            <el-pagination
                    v-if="resourceData.length > 0"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-size="pageSize"
                    layout="prev, pager, next, jumper"
                    :total="total">
            </el-pagination>
        </div>
        <ResourceDialog :dialogVisible="dialogVisible" :src="src" @closeDialog="closeDialog"></ResourceDialog>
    </div>
</template>

<script>
    import draggable from "vuedraggable";
    import ResourceDialog from "../../components/ResourceDialog";

    export default {
        name: "MatingDetailModule",
        props: ['select_list', 'module_list', 'searchInput'],
        components: {
            draggable,
            ResourceDialog
        },
        data() {
            return {
                typeList: [],
                classId: null,
                resourceData: [],
                adminBtnShow: true,
                checkAll: false,
                isIndeterminate: true,
                selectAppList: [],
                applicationIdList: [],
                src: "",
                dialogVisible: false,
                currentPage: 1,
                pageSize: 6,
                total: "",
            }
        },
        created() {
            this.getTypeList()
        },
        mounted() {
            this.selectClassify()
        },
        methods: {
            handleSizeChange(val) {
            },
            handleCurrentChange(val) {
                this.selectClassify(val);
            },
            downloadFile(item) {
                let src = item.file_path.url;
                let x = new XMLHttpRequest();
                x.open("GET", src, true);
                x.responseType = 'blob';
                x.onload = function (e) {
                    let url = window.URL.createObjectURL(x.response)
                    let a = document.createElement('a');
                    a.href = url
                    a.download = item.title
                    a.click()
                }
                x.send();
            },
            goBack() {
                this.$router.go(-1)
            },
            closeDialog() {
                this.src = '';
                this.dialogVisible = false
            },
            backBtn() {
                this.src = '';
                this.dialogVisible = false
            },
            viewDetail(item) {
                this.src = item.file_path.src;
                this.dialogVisible = true;
            },
            getTypeList() {
                this.$http.axiosGet(this.$api.meterial_mtlTypeList, res => {
                    if (res.code == 200) {
                        this.typeList = res.data;
                    }
                })
            },
            selectClassify(val) {
                this.selectAppList = [];
                let param = {
                    course_id: this.$route.query.course_id,
                    course_chapter: this.$route.query.zhangIndex,
                    course_node: this.$route.query.jieIndex,
                    page: val ? val : this.currentPage,
                    limit: 6
                }
                this.$http.axiosGetBy(this.$api.meterial_showMeterialList, param, res => {
                    if (res.code === 200) {
                        this.resourceData = res.data.data;
                        this.total = res.data.page_total;
                        this.resourceData.forEach(item => {
                            this.applicationIdList.push(item.id)
                            item.ctype = 6;
                        })
                    }
                })
            },
            selectApp(val) {
                let checkedCount = val.length;
                this.checkAll = checkedCount === this.resourceData.length;
                this.isIndeterminate = checkedCount > 0 && checkedCount < this.resourceData.length;
            },
            //全选
            handleCheckAllChange(val) {
                this.isIndeterminate = false;
                this.selectAppList = val ? this.applicationIdList : [];
            },
            //删除
            delAppBtn() {
                if (this.selectAppList.length === 0) {
                    this.$message.warning("请选择至少一个")
                    return
                }
                this.$confirm('删除后不能恢复，确定是否删除？', '删除', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let id = this.selectAppList.join(',')
                    this.$http.axiosGetBy(this.$api.delMeterial, {id: id}, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.msg);
                            this.selectClassify(this.classId);
                            this.selectAppList = [];
                        } else {
                            this.$message.error(res.msg);
                        }
                    }, (err) => {
                        console.log(err);
                    })
                }).catch(() => {
                    this.$message.info('已取消删除')
                });
            },
            onMove(evt) {
                let obj = evt.draggedContext.element;
                let arr = JSON.parse(JSON.stringify(this.select_list))
                for (let i = 0; i < arr.length; i++) {
                    if (JSON.stringify(arr[i]) === JSON.stringify(obj)) {
                        return false;
                    }
                }
                if (this.select_list.includes(obj)) {
                    return false;
                }
            },
            onEnd(evt) {
                let oldIndex = evt.oldIndex;
                let newIndex = evt.newIndex;
                if (this.select_list.length > 14) {
                    this.select_list.splice(newIndex + 1, 1)
                }
                this.module_list = this.select_list;
                this.$emit('handleList', this.select_list)
            },
            saveCourseCustom() {
                this.$emit('handleClick', true)
            },
            delModule(item, index) {
                this.select_list[index] = {};
                this.module_list = this.select_list;
                this.$emit('handleList', this.select_list);
                this.$forceUpdate();
            }
        }
    }
</script>

<style scoped lang="scss">
    .course-ware-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        background: #fff;

        .resource-classify {
            display: flex;
            align-items: center;
            padding: 26px 30px;
            position: relative;

            .back-btn {
                position: absolute;
                right: 30px;
            }

            span {
                display: inline-block;
                line-height: 1;
                font-size: 14px;
                padding: 8px 12px;
                border-radius: 4px;
                font-weight: 400;
                cursor: pointer;
            }

            .class-item {
                background: #ECF5FF;
                border: 1px solid #DCECFE;
                color: #409EFF;
            }
        }

        .administration {
            /*display: flex;*/
            .administration-box {
                padding: 0 30px;
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .admin-btn {
                    padding: 8px 28px;
                }
            }
        }

        .course-ware-list {
            flex: 1;
            width: 100%;
            height: 1%;

            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }

            .course-ware {
                padding: 0px 30px;
                display: flex;
                flex-wrap: wrap;

                .dataTabble {
                    width: 100%;
                    margin-top: 18px;
                    border-bottom: 1px solid #DDDDDD;

                    .table-head {
                        width: 100%;
                        height: 50px;
                        background: #E6F0FE;;

                        .table-head-title {
                            height: 50px;
                            font-size: 16px;

                            th {
                                color: #333333;
                                font-weight: 400;
                                width: 25%;
                            }

                            .th-title1 {
                                text-align: left;
                                padding-left: 40px;
                            }
                        }
                    }

                    .table-body {
                        height: 100%;
                        border-bottom: 1px solid #DDDDDD;

                        .table-trBox {
                            height: 100px;
                            position: relative;

                            td {
                                text-align: center;
                            }

                            .td-title {
                                text-align: left;
                                padding-left: 40px;
                            }

                            .opt-box {
                                height: 100px;

                                a {
                                    display: flex;
                                    align-items: center;
                                    width: 33%;
                                }
                            }
                        }
                    }
                }
            }
        }

        .page-box {
            text-align: center;
            margin-bottom: 60px;
        }

        .module-content {
            display: flex;
            justify-content: space-between;
            padding: 19px 20px;
            /*position: absolute;*/
            /*bottom: 20px;*/
            /*left: 0;*/
            /*right: 0;*/
            align-items: center;
            box-shadow: 0px -5px 10px -5px rgba(0, 0, 0, 0.18);

            .module {
                flex: 1;
                width: 1%;

                ::v-deep .el-scrollbar__wrap .el-scrollbar__view {
                    white-space: nowrap;
                }
            }

            .module-list {
                display: flex;
                position: relative;
                padding: 6px;

                .new-module-item {
                    position: relative;
                    width: 60px;
                    height: 60px;
                    border-radius: 10px;
                    margin-right: 15px;
                }

                .module-item {
                    position: relative;
                    width: 60px;
                    height: 60px;
                    border: 1px dashed #CCCCCC;
                    border-radius: 10px;
                    margin-right: 13px;
                }

                .item-cover {
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    border-radius: 50%;

                    i {
                        font-size: 20px;
                        color: #fff;
                    }
                }

                .item-cover1 {
                    width: 60px;
                    height: 60px;
                    line-height: 60px;
                    text-align: center;
                    border-radius: 50%;
                    position: relative;

                    i {
                        font-size: 20px;
                        color: #fff;
                    }

                    .show-img {
                        width: 100%;
                        height: 100%;

                        .img-box {
                            max-width: 100%;
                            max-height: 100%;
                            border-radius: 50%
                        }
                    }
                }

                .show1 {
                    background: #61BCEB;
                }

                .show2 {
                    background: #3DCAC8;
                }

                .show3 {
                    background: #F0BC49;
                }

                .show4 {
                    background: #EC75A4;
                }

                .show5 {
                    background: #79ACDC;
                }

                .show6 {
                    background: #61BCEB;
                }

                .show7 {
                    background: #5AB9E9;
                }

                .courseware {
                    background: linear-gradient(0deg, #F5D971, #EDA92E);
                }

                .textbook {
                    background: linear-gradient(0deg, #7BCBF2, #44ACE3);
                }

                .lesson-plan {
                    background: linear-gradient(0deg, #53DED1, #2BB9BF);
                }

                .micro-lesson {
                    background: linear-gradient(0deg, #EE89B5, #EA5D90);
                }

                .animation {
                    background: linear-gradient(0deg, #F39E72, #EC6C6C);
                }

                .test-center {
                    background: linear-gradient(0deg, #8CC5EC, #5F8AC7);
                }

                .training-center {
                    background: linear-gradient(0deg, #ED83AA, #EC6B6F);
                }

                .knowledge-points {
                    background: linear-gradient(0deg, #77C9F1, #47AEE4);
                }

                .material-library {
                    background: linear-gradient(0deg, #51DCD0, #2CBAC0);
                }

                .case-library {
                    background: linear-gradient(0deg, #F5D971, #EDA92E);
                }

                .related-copywriting {
                    background: linear-gradient(0deg, #8BC3EB, #628EC9);
                }

                .personal-info {
                    background: linear-gradient(0deg, #51DCD0, #2FBDC1);
                }

                .del-btn {
                    width: 18px;
                    height: 18px;
                    background: #FF0000;
                    color: #ffffff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    position: absolute;
                    right: 0;
                    top: 0;
                    opacity: 0;
                    cursor: pointer;
                    z-index: 1;
                }

                .show-title {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    z-index: 1;
                }

                .new-module-item:before {
                    content: "";
                    width: 0;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    opacity: 0;
                }


                .new-module-item:hover:before {
                    width: 100%;
                    left: 0;
                    opacity: 0.5;
                }

                .new-module-item:hover .del-btn {
                    opacity: 1;
                }

            }

            .module-save-btn {
                width: 60px;
                height: 60px;
                background: #ECF5FF;
                border: 1px solid #DCECFE;
                border-radius: 4px;
                color: #409EFF;
                font-size: 14px;
                line-height: 60px;
                text-align: center;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    .no-data {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        margin-top: 50px;
    }
</style>